import Vue from 'vue'
import App from './App.vue'
import global_var from "./global";

Vue.config.productionTip = false

/*import fun from './fun'   // 路径示公共JS位置决定
Vue.use(fun);*/

// 获取基金估值数据全局函数，在远程脚本中调用函数 https://fundgz.1234567.com.cn/js/" + fundCode + ".js
Vue.prototype.jsonpgz = function (data){
  // eslint-disable-next-line no-prototype-builtins
  if(data.hasOwnProperty('fundcode') && data.fundcode){
    global_var.fundValuation[data.fundcode] = data;
  }
}

new Vue({
  render: h => h(App),
}).$mount('#page')
