<template>
<div id="page">
<div id="filter">
    <!--<img alt="Vue logo" src="./assets/logo.png">-->
    <input id="file_name" name="file_name" type='file' ref="file" @change="importFile">
    <select id="fund_name" name="fund_name" v-model="FundSelected" @change="onChangeFund(false)">
      <option :value="item.name" :key="item.name" v-for="item in FundList" >{{item.name}}</option>
    </select>
    <button id="more_less" class="button" @click="show_more_filter($event)">More +</button>
    <button id="show_time" class="button" @click="show_time_select($event)">ShowTime</button>
    <button id="pull_net_fund_data" class="button" @click="onChangeFund(false)">PULL</button>
    <button id="pull_net_fund_valuation" class="button" @click="onChangeFund(true)">Valuation</button>
    <button id="pull_net_fund_valuation_trend" class="button" @click="getValuationTrend">Trend</button>
    <br>
    <div id="filter_more">
    <input type="checkbox" name="add_item" id="add_item_1" class="add_item" value="金额" @click="onChangeFundItem($event)"><label for="add_item_1">金额</label>
    <input type="checkbox" name="add_item" id="add_item_2" class="add_item" value="累计收益" @click="onChangeFundItem($event)"><label for="add_item_2">累计收益</label>
    <input type="checkbox" name="add_item" id="add_item_3" class="add_item" value="收益极值" @click="onChangeFundItem($event)"><label for="add_item_3">收益极值</label>
    <input type="checkbox" name="add_item" id="add_item_4" class="add_item" value="收益明细" @click="onChangeFundItem($event)"><label for="add_item_4">收益明细</label>
    <input type="checkbox" name="add_item" id="add_item_5" class="add_item" value="单位净值" @click="onChangeFundItem($event)"><label for="add_item_5">单位净值</label>
    <input type="checkbox" name="add_item" id="add_item_10" class="add_item" value="持仓成本价" @click="onChangeFundItem($event)"><label for="add_item_10">持仓成本价</label>
    <input type="checkbox" name="add_item" id="add_item_6" class="add_item" value="交易" @click="onChangeFundItem($event)"><label for="add_item_6">交易</label>
    <input type="checkbox" name="add_item" id="add_item_7" class="add_item" value="交易点" @click="onChangeFundItem($event)"><label for="add_item_7">交易点</label>
    <input type="checkbox" name="add_item" id="add_item_8" class="add_item" value="回撤" @click="onChangeFundItem($event)"><label for="add_item_8">回撤</label>
    <input type="checkbox" name="add_item" id="add_item_9" class="add_item" value="持有份额" @click="onChangeFundItem($event)"><label for="add_item_9">份额</label>
    <label for="add_reference_line">&nbsp;&nbsp;参考线</label><input type="number" name="add_reference_line" id="add_reference_line" class="add_item" value="0.2" @change="onChangeFundItem($event)">
    </div>
    <div id="fund_latest_status"><p>
        <span class="fund_item_name">日期：</span><span id="fund_date" class="fund_item_value"></span>
        <span class="fund_item_name">金额：</span><span id="fund_money" class="fund_item_value"></span>
        <span class="fund_item_name">昨日收益：</span><span id="fund_latest_income" class="fund_item_value"></span>
        <span class="fund_item_name">持有收益：</span><span id="fund_income" class="fund_item_value"></span>
        <span class="fund_item_name">收益率：</span><span id="fund_yield" class="fund_item_value"></span>
    </p>
    <p id="fund_valuation"></p>
    </div>
  <div id="fund_time"><p>
    <span class="fund_item_time" @click="selectTime(1)">1月</span>
    <span class="fund_item_time" @click="selectTime(3)">3月</span>
    <span class="fund_item_time" @click="selectTime(6)">6月</span>
    <span class="fund_item_time" @click="selectTime(12)">1年</span>
    <span class="fund_item_time" @click="selectTime(24)">2年</span>
    <span class="fund_item_time" @click="selectTime(36)">3年</span>
    <span class="fund_item_time" @click="selectTime(60)">5年</span>
    <span class="fund_item_time" @click="selectTime(-2)">今年</span>
    <span class="fund_item_time" @click="selectTime(-1)">最大</span>
  </p></div>
</div>
<div id="main">
    <div id="container" class="chart" ref="chart"></div>
</div>

</div>
</template>

<script>
let XLSX = require('xlsx');
let Echarts = require('echarts');

import global_var from './global';

function getNewFundDataToChart(fundCode,j_data,type = 0){
  if(!fundCode.match(/^[0-9]{6}$/)) {
    let fundCodeArr = /\((.+)\)/.exec(fundCode);
    fundCode = fundCodeArr[1];
  }
  setNewFundData(fundCode,j_data);
  // eslint-disable-next-line no-prototype-builtins
  if(global_var.newItemNetfundData.hasOwnProperty(fundCode) && global_var.newItemNetfundData[fundCode].length > 0){
    if(global_var.newItemNetfundData[fundCode][global_var.newItemNetfundData[fundCode].length-1].净值日期 !== j_data[j_data.length-1].净值日期){
      global_var.newItemNetfundData[fundCode].forEach (function (v) {
        j_data.push(v);
      });
    }
  }
  for(let v in j_data){
    j_data[v].收益明细 = Math.round(j_data[v].收益明细 * 100)/100;
    j_data[v].持有收益 = Math.round(j_data[v].持有收益 * 100)/100;
    j_data[v].累计收益 = Math.round(j_data[v].累计收益 * 100)/100;
    j_data[v].金额 = Math.round(j_data[v].金额 * 100)/100;
    j_data[v].持仓成本价 = Math.round(j_data[v].持仓成本价 * 1000)/1000;
    j_data[v].持有收益率 = Math.round(j_data[v].持有收益率 * 10000)/10000;
    j_data[v].回撤 = Math.round(j_data[v].回撤 * 10000)/10000;
  }
  if(type){
    if(global_var.newNetfundData[fundCode].fS_code !== fundCode) return j_data;
    let fundNetData = global_var.newNetfundData[fundCode].Data_ACWorthTrend;
    for(let i = fundNetData.length - 1; i>=0; i--){
      if(fundNetData[i][0] >= new Date(j_data[0].净值日期).getTime()) continue;
      let tempObj = {};
      let fundDate = new Date(fundNetData[i][0]);
      tempObj.净值日期 = fundDate.getFullYear() + '/' + (fundDate.getMonth()+1) + '/' + fundDate.getDate();
      tempObj.累计净值 = fundNetData[i][1];
      tempObj.单位净值 = global_var.newNetfundData[fundCode].Data_netWorthTrend[i].y;
      tempObj.持有收益 = tempObj.持有收益率 = tempObj.收益明细 = tempObj.金额 = tempObj.累计收益 = tempObj.交易 = tempObj.交易点 = tempObj.转折点 = tempObj.回撤 = 'null';
      j_data.unshift(tempObj);
    }
  }
  return j_data;
}

function setNewFundData(fundCode,j_data) {
  // eslint-disable-next-line no-prototype-builtins
  if(global_var.newItemNetfundData.hasOwnProperty(fundCode)) return;
  // eslint-disable-next-line no-prototype-builtins
  if(!global_var.newNetfundData.hasOwnProperty(fundCode) || !global_var.newNetfundData[fundCode]){
    initJsFundData(fundCode);
    return;
  }
  let lastData = j_data[j_data.length - 1];
  let FundItem = global_var.newNetfundData[fundCode].Data_netWorthTrend;
  let result = [];
  let jzrq = FundItem[FundItem.length - 1].x;
  let LastdateToTime = new Date(lastData.净值日期).getTime();
  let diff = Math.floor((jzrq - LastdateToTime)/86400000);
  for(let i = 0; i < diff; i++){
    let j = diff - i;
    let fundDateToTime = FundItem[FundItem.length - j].x;
    if(fundDateToTime > LastdateToTime) {
      let fundDate = new Date(fundDateToTime);
      let v = {};
      v.净值日期 = fundDate.getFullYear() + '/' + (fundDate.getMonth()+1) + '/' + fundDate.getDate();
      let fundWorth = FundItem[FundItem.length - j].y;
      v.单位净值 = fundWorth;
      // v.累计净值 = Number(lastData.累计净值) + (fundWorth - Number(lastData.单位净值));
      v.累计净值 = global_var.newNetfundData[fundCode].Data_ACWorthTrend[global_var.newNetfundData[fundCode].Data_ACWorthTrend.length - j][1];
      v.金额 = (fundWorth * Number(lastData.持有份额)).toFixed(2);
      v.持有份额 = Number(lastData.持有份额);
      let jiaoyi = (!lastData.交易 && lastData.交易 !== 0 && lastData.交易 !== "") ? Number(lastData.交易) : 0;
      v.收益明细 = (v.金额 - Number(lastData.金额)) - jiaoyi;
      v.累计收益 = v.收益明细 + Number(lastData.累计收益);
      v.持有收益 = v.收益明细 + Number(lastData.持有收益);
      v.持有收益率 = v.持有收益/(v.金额 - v.持有收益);
      v.持仓成本价 = (v.金额 - v.持有收益)/v.持有份额;
      v.交易 = "";
      result.push(v);
      lastData = v;
    }
  }
  global_var.newItemNetfundData[fundCode] = result;
  removeFundJS(fundCode);
}

function initJsFundData(fundCode) {
  if(!fundCode.match(/^[0-9]{6}$/)) {
    let fundCodeArr = /\((.+)\)/.exec(fundCode);
    fundCode = fundCodeArr[1];
  }
  // eslint-disable-next-line no-prototype-builtins
  if(!global_var.newNetfundData.hasOwnProperty(fundCode)){
    let url = "https://fund.eastmoney.com/pingzhongdata/" + fundCode + ".js?time=" + new Date().getTime();
    getDataByJS(url,fundCode);
  }
}

function getDataByJS(url,JSID){
  let fundData = document.createElement("script");
  fundData.type = "text/javascript";
  fundData.src = url;
  fundData.id = JSID;
  if (!document.getElementById(JSID)) {
    document.getElementsByTagName("head")[0].appendChild(fundData);
  }
  setTimeout(function () {
    if (window.fS_code === JSID) {
      global_var.newNetfundData[JSID] = {
        fS_code: window.fS_code,
        Data_netWorthTrend: window.Data_netWorthTrend,
        Data_ACWorthTrend: window.Data_ACWorthTrend
      };
    }
  }, 500);
}

function removeFundJS(JSID) {
  let fundJs = document.getElementById(JSID);
  if (fundJs) {
    document.getElementsByTagName("head")[0].removeChild(fundJs);
  }
}

function setFundValuation(fundCode,j_data){
  if(!fundCode.match(/^[0-9]{6}$/)) {
    let fundCodeArr = /\((.+)\)/.exec(fundCode);
    fundCode = fundCodeArr[1];
  }

  let JSID = "valuation_" + fundCode + "_" + new Date().setSeconds(0,0);
  let fundValuation = document.createElement("script");
  fundValuation.type = "text/javascript";
  fundValuation.src = "https://fundgz.1234567.com.cn/js/" + fundCode + ".js?rt=" + new Date().getTime();
  fundValuation.id = JSID;
  if (!document.getElementById(JSID)) {
    document.getElementsByTagName("head")[0].appendChild(fundValuation);
  }

  let v = {};
  let lastData = j_data[j_data.length - 1];
  let data = global_var.fundValuation[fundCode];

  if(data) {
    let fundDate = new Date(data.gztime.replace(/-/g, "/"));
    let fundDateTimeMs = fundDate.setHours(0,0,0,0);

    if(new Date().setHours(0,0,0,0) === fundDateTimeMs && new Date(lastData.净值日期).setHours(0,0,0,0) !== fundDateTimeMs){
      v.净值日期 = fundDate.getFullYear() + '/' + (fundDate.getMonth()+1) + '/' + fundDate.getDate();
      v.单位净值 = Number(data.gsz);
      v.累计净值 = Number(lastData.累计净值) + (v.单位净值 - Number(lastData.单位净值));
      v.金额 = Math.round(((v.单位净值 * Number(lastData.持有份额)).toFixed(2)) * 100)/100;
      v.持有份额 = Number(lastData.持有份额);
      let jiaoyi = (!lastData.交易 && lastData.交易 !== 0 && lastData.交易 !== "") ? Number(lastData.交易) : 0;
      v.收益明细 = Math.round(((v.金额 - Number(lastData.金额)) - jiaoyi) * 100)/100;
      v.累计收益 = Math.round((v.收益明细 + Number(lastData.累计收益)) * 100)/100;
      v.持有收益 = Math.round((v.收益明细 + Number(lastData.持有收益)) * 100)/100;
      v.持有收益率 = Math.round((v.持有收益/(v.金额 - v.持有收益)) * 10000)/10000;
      v.持仓成本价 = Math.round(((v.金额 - v.持有收益)/v.持有份额) * 100)/100;
      j_data.push(v);
      let fund_day_income = (v.收益明细 > 0)?'<span style="color:red">' + v.收益明细 + '</span>':'<span style="color:green">' + v.收益明细 + '</span>';
      let fund_worth = (Number(data.gszzl) > 0)?'<span style="color:red">' + data.gszzl + '%</span>':'<span style="color:green">' + data.gszzl + '%</span>';
      let fund_income = (v.持有收益 > 0)?'<span style="color:red">' + v.持有收益 + '</span>':'<span style="color:green">' + v.持有收益 + '</span>';
      let fund_yield = Math.round(v.持有收益率 * 10000)/100 + '%';
      let fund_yield_html = (v.持有收益率 > 0)?'<span style="color:red">' + fund_yield + '</span>':'<span style="color:green">' + fund_yield + '</span>';
      document.getElementById('fund_valuation').innerHTML =
          '<span class="fund_item_name">估值：</span><span class="fund_item_value">' + data.gztime + '</span>'
          + '<span class="fund_item_name">涨幅：</span><span class="fund_item_value">' + fund_worth + '</span>'
          + '<span class="fund_item_name">日收益：</span><span class="fund_item_value">' + fund_day_income + '</span>'
          + '<span class="fund_item_name">估算收益：</span><span class="fund_item_value">' + fund_income + '</span>'
          + '<span class="fund_item_name">收益率：</span><span class="fund_item_value">' + fund_yield_html + '</span>'
      ;
    }
  }
  return j_data;
}

function setLatestFundData(data){
  let fund_day_income = Math.round(data.收益明细 * 100)/100;
  let fund_income = Math.round(data.持有收益 * 100)/100;
  let fund_yield = Math.round(data.持有收益率 * 10000)/100 + '%';
  document.getElementById('fund_date').innerHTML = data.净值日期;
  document.getElementById('fund_money').innerHTML = Math.round(data.金额 * 100)/100;
  document.getElementById('fund_latest_income').innerHTML = (fund_day_income > 0)?'<span style="color:red">' + fund_day_income + '</span>':'<span style="color:green">' + fund_day_income + '</span>';
  document.getElementById('fund_income').innerHTML = (fund_income > 0)?'<span style="color:red">' + fund_income + '</span>':'<span style="color:green">' + fund_income + '</span>';
  document.getElementById('fund_yield').innerHTML = (data.持有收益率 > 0)?'<span style="color:red">' + fund_yield + '</span>':'<span style="color:green">' + fund_yield + '</span>';
}

function setChartOption(fund_data, fund_name) {
  let data_zoom = [
    {
      type: 'inside',
      start: 0,
      end: 100
    },
    {
      start: 0,
      end: 100
    }
  ];
  if (fund_name === '工银战略转型(000991)') {
    data_zoom = [{type: 'inside', startValue: '2021/5/13', end: 100}, {startValue: '2021/5/13', end: 100}];
  }
  global_var.chart_option = {
    title: {
      text: fund_name,
      textStyle: {
        fontSize:12
      }
    },
    legend: {
      data: ['累计净值', '持有收益率', '持有收益'],
      textStyle: {
        fontSize:12
      }
    },
    dataset: {source: fund_data},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: data_zoom,
    xAxis: {
      type: 'category',
      boundaryGap: false
    },
    yAxis: [
      {
        type: 'value',
        name: '累计净值',
        min: function (value) {
          return (value.min - 0.1).toFixed(1);
        },
        max: function (value) {
          return (value.max + 0.1).toFixed(1);
        },
        position: 'left',
        axisLabel: {
          textStyle: {
            fontSize:10
          }
        }
      },
      {
        type: 'value',
        name: '收益率',
        position: 'right',
        offset: 35,
        axisLabel: {
          textStyle: {
            fontSize:10
          },
          formatter: function (value) {
            return (value * 100) + '%';
          }
        }
      },
      {
        type: 'value',
        name: '收益',
        position: 'right',
        axisLabel: {
          textStyle: {
            fontSize:10
          }
        }
      }
    ],
    series: [
      {
        name: '累计净值',
        yAxisIndex: 0,
        type: 'line',
        symbol: 'none',
        encode: {
          x: '净值日期',
          y: '累计净值'
        }
      },
      {
        name: '持有收益率',
        yAxisIndex: 1,
        type: 'line',
        symbol: 'none',
        encode: {
          x: '净值日期',
          y: '持有收益率'
        }
      },
      {
        name: '持有收益',
        yAxisIndex: 2,
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: 'rgb(255, 70, 131)'
        },
        areaStyle: {
          color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(255, 158, 68)'
            },
            {
              offset: 1,
              color: 'rgb(255, 70, 131)'
            }
          ])
        },
        encode: {
          x: '净值日期',
          y: '持有收益'
        }
      }
    ]
  };
}

export default {
  name: 'chart',
  data() {
    return {
      chart: '',
      FundData: [],
      FundList: [{name:'银河行业混合(519670)'},{name:'易方达上证50(110003)'},{name:'工银战略转型(000991)'},{name:'广发中证500(002903)'}],
      FundSelected : ''
    }
  },
  created(){
    this.FundSelected = this.FundList[0].name;
  },
  mounted() {
    /*this.draw_Chart()*/
    initJsFundData(document.getElementById("fund_name").value);
    window.jsonpgz = this.jsonpgz; /* 将定义的获取基金估值数据全局函数赋予window，以便在加载远程脚本时调用 */
  },
  methods: {
    getValuationTrend(){
      let fundCode = document.getElementById("fund_name").value;
      if(!fundCode.match(/^[0-9]{6}$/)) {
        let fundCodeArr = /\((.+)\)/.exec(fundCode);
        fundCode = fundCodeArr[1];
        let d = new Date();
        let t = d.getFullYear().toString() + (d.getMonth()+1) + d.getDate() + d.getHours() + d.getMinutes() + d.getSeconds();
        let url = "https://j4.dfcfw.com/charts/pic6/" + fundCode + ".png?v=" + t;
        let iTop = (window.screen.height-30-281)/2;
        let iLeft = (window.screen.width-10-451)/2;
        window.open(url,'_blank','width=451,height=281,toolbar=no,scrollbars=no,menubar=no,left='+iLeft+',top='+iTop);
      }
    },
    onChangeFund(getFundValuation){
      if(!global_var.wb){return;}
      let fund_name = document.getElementById("fund_name").value;
      let j_data = XLSX.utils.sheet_to_json(global_var.wb.Sheets[fund_name],{
        defval: 'null',
        raw: false,
        dateNF: global_var.date_format
      });
      j_data = getNewFundDataToChart(fund_name,j_data);
      setLatestFundData(j_data[j_data.length - 1]);
      document.getElementById('fund_valuation').innerHTML = "";
      if(getFundValuation) j_data = setFundValuation(fund_name,j_data);
      this.FundData = j_data;
      setChartOption(j_data,fund_name);
      this.selectTime(12);
      /*if (document.getElementById("container") != null) {
        Echarts.dispose(document.getElementById("container"));
        this.chart = Echarts.init(document.getElementById("container"));
      }
      this.chart.setOption(global_var.chart_option);*/
    },
    onChangeFundItem(event){
      if(!global_var.wb){return;}
      let obj_value = event.target.value;
      let obj_checked = event.target.checked;
      if(obj_value === '收益极值'){
        if(obj_checked){
          global_var.chart_option.series[2].markPoint = {data: [{type: 'max', name: '最大值'}, {type: 'min', name: '最小值'}]};
          global_var.chart_option.series[2].markLine = {data: [{type: 'average', name: '平均值'}]};
        }else{
          delete global_var.chart_option.series[2].markPoint;
          delete global_var.chart_option.series[2].markLine;
        }
      }else {
        if(obj_checked){
          global_var.chart_option.legend.data.push(obj_value);
          let series_item = {name:obj_value,yAxisIndex:2,type:"line",symbol:"none",encode:{x:'净值日期',y:obj_value}};
          if(obj_value === "交易点"){
            series_item = {name:obj_value,yAxisIndex:0,type:"scatter",z:3,symbol:"circle",symbolSize:5,itemStyle:{color:'red'},encode:{x:'净值日期',y:obj_value}};
          }
          if(obj_value === "单位净值" || obj_value === "持仓成本价"){series_item.yAxisIndex = 0;}
          if(obj_value === "收益明细" || obj_value === "交易"){
            series_item.type = "bar";
            series_item.itemStyle = {normal:{color:function(params){let index_color = params.value[obj_value];if(index_color > 0){return '#f11200';}else{return '#00a800';}}}};
          }
          if(obj_value === "回撤"){series_item.type = "bar";series_item.yAxisIndex = 1;}
          global_var.chart_option.series.push(series_item);
        }else{
          for (let i = 0; i < global_var.chart_option.series.length; i ++) {
            if(global_var.chart_option.series[i].name === obj_value){
              global_var.chart_option.legend.data.splice(i,1);
              global_var.chart_option.series.splice(i,1);
              break;
            }
          }
        }
      }
      if(event.target.name === 'add_reference_line'){
        global_var.chart_option.series[1].markLine = {data: [{yAxis: event.target.value}]};
      }

      if (document.getElementById("container") != null) {
        Echarts.dispose(document.getElementById("container"));
        this.chart = Echarts.init(document.getElementById("container"));
      }
      this.chart.setOption(global_var.chart_option,true);
    },
    selectTime(v){
      let fund_name = document.getElementById("fund_name").value;
      let j_data = this.FundData;
      if(!j_data || j_data.length === 0) {
        if(!global_var.wb){return;}
        j_data = XLSX.utils.sheet_to_json(global_var.wb.Sheets[fund_name],{
          defval: 'null',
          raw: false,
          dateNF: global_var.date_format
        });
      }

      let startDate = 0;
      if(v === -1){
        j_data = getNewFundDataToChart(fund_name,j_data,1);
        this.FundData = j_data;
        setChartOption(j_data,fund_name);
      }else{
        let nowDate = new Date();
        let year = nowDate.getFullYear();
        let month = nowDate.getMonth()+1;
        let date = nowDate.getDate();
        let month2 = month - v;
        if(v > 0){
          if (month2 <= 0) {
            year = year - (month2 / 12 === 0 ? 1 : Math.abs(parseInt((month2 / 12).toString())) + 1);
            month2 = 12 - (Math.abs(month2) % 12);
          }
        }else if(v === -2){
          month2 = date = 1;
        }
        startDate = year + '/' + month2 + '/' + date;
        let monthAllDays = (new Date(year, month2, 0)).getDate();
        for(let i = 0;i < 9; i++){
          let is_ok = false;
          let date_i = date + i;
          if (date_i > monthAllDays){if(month2<12){month2++;}else{month2=1;year++;} date = date_i = i = 1;}
          startDate = year + '/' + month2 + '/' + date_i;
          for(let j in j_data){
            if(j_data[j].净值日期 === startDate) {is_ok = true;break;}
          }
          if(is_ok === true) break;
        }
      }
      global_var.chart_option.dataZoom = [{type: 'inside', startValue: startDate, end: 100}, {startValue: startDate, end: 100}];
      if (document.getElementById("container") != null) {
        Echarts.dispose(document.getElementById("container"));
        this.chart = Echarts.init(document.getElementById("container"));
      }
      this.chart.setOption(global_var.chart_option,true);
    },
    importFile() {
      if (!this.$refs.file.files) {
        return;
      }

      let f = this.$refs.file.files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let data = e.target.result;
        global_var.wb = XLSX.read(data, {
          type: 'binary',
          cellDates: true,
          cellText: false
        });

        let fund_name = document.getElementById("fund_name").value;
        let j_data = XLSX.utils.sheet_to_json(global_var.wb.Sheets[fund_name], {
          defval: 'null',
          raw: false,
          dateNF: global_var.date_format
        });
        j_data = getNewFundDataToChart(fund_name,j_data);
        setLatestFundData(j_data[j_data.length - 1]);
        if(j_data){
          this.FundData = j_data;
          setChartOption(j_data, fund_name);
          this.chart = Echarts.init(document.getElementById("container"));
          this.chart.setOption(global_var.chart_option);
        }
      }
      reader.readAsBinaryString(f);
    },
    show_more_filter(e){
      let filter_more = document.getElementById('filter_more');
      let fund_latest_status = document.getElementById('fund_latest_status');
      let fund_time = document.getElementById('fund_time');
      if(filter_more.style.display === 'block'){
        filter_more.style.display='none';
        e.target.innerHTML = "More +";
        fund_latest_status.style.display='block';
        fund_time.style.display='none';
      }else{
        filter_more.style.display='block';
        e.target.innerHTML = "Less -";
        fund_latest_status.style.display='none';
        fund_time.style.display='none';
      }
    },
    show_time_select(e){
      let filter_more = document.getElementById('filter_more');
      let fund_latest_status = document.getElementById('fund_latest_status');
      let fund_time = document.getElementById('fund_time');
      if(fund_time.style.display === 'block'){
        filter_more.style.display='none';
        e.target.innerHTML = "ShowTime";
        fund_latest_status.style.display='block';
        fund_time.style.display='none';
      }else{
        filter_more.style.display='none';
        e.target.innerHTML = "HideTime";
        fund_latest_status.style.display='none';
        fund_time.style.display='block';
      }
    },
  }
};
</script>

<style>
html,body{width:100vw;height:100vh;margin:0}
#page{display:flex;flex-flow:column;width:100vw;height:100vh;}
#filter{margin:6px auto;text-align:center}
#filter input,#filter select{font-size:16px;padding:2px 5px}
#main{flex:1;display:flex}
#main>div,.chart{flex:1;margin:1%}
#filter_more,#fund_time{display:none;margin-top:5px}
#fund_latest_status,#fund_time{margin-top:5px;padding:3px 0 2px 0}
#fund_latest_status p,#fund_time p{margin:0 auto}
.fund_item_time{margin-left:20px;padding:3px 5px;cursor:pointer}
.fund_item_time:hover{background:#4c74b1;color:#fff}
.button{margin-left:15px;height:25px;}
#add_reference_line{width:50px;text-align:center}
.fund_item_value{font-weight:bold;margin-right:20px}
@media (max-width: 768px) {
  #filter{margin:3px auto;}
  #filter input,#filter select{font-size:12px;padding:initial}
  #file_name,#fund_name{width:23%}
  .button{height:auto;font-size:12px}
  #filter{font-size:12px;width:100vw}
  .chart{font-size:12px}
}
</style>
