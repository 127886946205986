const wb = '';
const date_format = 'yyyy/m/d';
const chart_option = {};
const newNetfundData = [];
const newItemNetfundData = [];
const fundValuation = [];

export default {
    wb,
    date_format,
    chart_option,
    newNetfundData,
    newItemNetfundData,
    fundValuation
}